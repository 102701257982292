<template>
  <div class="page-view">
    <PartnershipPage />
  </div>
</template>

<script>
import PartnershipPage from "../components/Partnership.vue";
export default {
  name: "Partnership",
  components: {
    PartnershipPage
  },
  methods: {
    scroll: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  },
  beforeMount: function() {
    setTimeout(() => this.scroll(), 1000);
  }
};
</script>

<style></style>
