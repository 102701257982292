<template>
  <section class="partnership-content">
    <div class="first-ham-container">
      <div class="first-ham">
        <Menu />
      </div>
    </div>

    <!-- INTRO -->
    <div class="intro-wrapper">
      <div class="vertical-line"></div>
      <div class="partnership-intro">
        <div class="heading">
          <h1>Współpraca</h1>
          <div class="line"></div>
        </div>
        <div class="intro-content" v-scrollanimation>
          <div class="partnership-img"></div>
          <div class="intro-text">
            <span class="intro-text">Dowiedz</span><br />
            <span class="intro-text">się</span><br />
            <span class="intro-text">jak</span><br />
            <span class="intro-text">wygląda</span><br />
            <span class="intro-text">współpraca</span><br />
            <div class="arrow-icon">
              <b-icon icon="arrow-down" scale="6"></b-icon>
            </div>
          </div>
          <div class="nd-line"></div>
        </div>
        <div class="icon">
          <b-icon icon="gear-wide" v-bind:scale="scale" rotate="20"></b-icon>
        </div>
      </div>
    </div>
    <!--END INTRO -->

    <!-- MENU WITH MINI LOGO -->
    <div class="title-wrapper">
      <div class="first-ham-container">
        <div class="first-ham">
          <Menu />
        </div>
      </div>
      <div class="mini-logo">
        <a href="/">
          <span class="logo-font">
            Web
            <img class="logo" src="../assets/logo.png" />
            Vision
          </span>
        </a>
      </div>
    </div>
    <!--END OF MENU WITH MINI LOGO -->

    <!-- MAIN IDEA -->

    <div class="main-idea-wrapper">
      <div class="main-idea" v-scrollanimation>
        Każdy nasz projekt<br />
        traktujemy indywidualnie, jednak wszystkie powstają według jednego
        schematu.
      </div>
    </div>

    <!-- MENU WITH MINI LOGO -->
    <div class="first-ham-container"></div>
    <div class="title-divider"></div>
    <div class="title-wrapper">
      <div class="second-ham-container">
        <div class="first-ham">
          <Menu />
        </div>
      </div>
      <div class="title" v-scrollanimation>Współpraca</div>
      <div id="logo-next-to-title" class="mini-logo">
        <a href="/">
          <span class="logo-font">
            Web
            <img class="logo" src="../assets/logo.png" />
            Vision
          </span>
        </a>
      </div>
    </div>
    <!--END OF MENU WITH MINI LOGO -->

    <!-- no1 -->
    <div class="content-wrapper">
      <div class="text-wrapper" v-scrollanimation>
        <div class="nr nr-1">1</div>
        <div class="text-content text-margin-left">
          <h2>Kontakt</h2>
          <span class="text text-st">
            W pierwszej kolejności <br />
            musisz wyciągnąć do nas<br />
            rękę. Napisz lub skontaktuj <br />
            się telefonicznie :)</span
          >
        </div>
        <div class="text-divider"></div>
      </div>
      <div class="content-icon">
        <b-icon icon="chat-right-quote" v-bind:scale="scale"></b-icon>
      </div>
    </div>
    <!--END OF no1 -->

    <!-- MENU WITH MINI LOGO -->
    <div class="title-wrapper">
      <div class="second-ham-container">
        <div class="first-ham">
          <Menu />
        </div>
      </div>

      <div class="mini-logo">
        <a href="/">
          <span class="logo-font">
            Web
            <img class="logo" src="../assets/logo.png" />
            Vision
          </span>
        </a>
      </div>
    </div>
    <!--END OF MENU WITH MINI LOGO -->
    <div class="intro-wrapper">
      <div class="vertical-line-nd"></div>
      <div class="content-wrapper-nd" v-scrollanimation>
        <!-- no2 
        <div class="content-icon-second">
          <b-icon icon="people-fill" v-bind:scale="scale"></b-icon>
        </div>
        <div class="text-wrapper-second">
           --->
        <div class="content-icon-second">
          <b-icon icon="people-fill" v-bind:scale="scale"></b-icon>
        </div>
        <div class="nr nr-2">2</div>
        <div class="text-content talking">
          <h2>Rozmowa</h2>
          <span class="text">
            Podczas rozmowy zadamy<br />
            Ci kilka prostych, a zarazem<br />
            niezbędnych pytań. Dzięki temu<br />
            będziemy mogli przedstawić Ci <br />idealnie dopasowaną ofertę.
          </span>
          <div class="text-divider nd-divider"></div>
        </div>
      </div>
      <!-- 
      </div>
      END OF no2 -->

      <div class="vertical-line-nd-rght"></div>
    </div>
    <!-- MENU WITH MINI LOGO -->
    <div class="title-wrapper">
      <div class="second-ham-container">
        <div class="first-ham">
          <Menu />
        </div>
      </div>

      <div class="mini-logo">
        <a href="/">
          <span class="logo-font">
            Web
            <img class="logo" src="../assets/logo.png" />
            Vision
          </span>
        </a>
      </div>
    </div>
    <!--END OF MENU WITH MINI LOGO -->

    <!-- no3 -->
    <div class="third-wrapper" v-scrollanimation>
      <div class="text-wrapper">
        <div class="nr third">3</div>
        <div class="text-content-third">
          <h2>Projekt</h2>
          <span class="text">
            Nasz grafik stworzy dla Ciebie <br />
            wstępny projekt graficzny.<br />
            Jeżeli Cie usatysfakcjonuje,<br />
            przystąpimy do pracy nad kodowaniem <br
          /></span>
        </div>
      </div>

      <div class="content-icon-third">
        <b-icon icon="eye-fill" v-bind:scale="scale"></b-icon>
      </div>
    </div>
    <!--END OF no3 -->

    <div>
      <!-- no4 -->
      <div class="content-wrapper-fourth">
        <div class="content-icon-fourth">
          <b-icon icon="tools" v-bind:scale="scale"></b-icon>
        </div>
        <div class="text-wrapper-fourth" v-scrollanimation>
          <div class="nr-two inline">4</div>
          <div class="inline th-tittle"><h2>Kodowanie</h2></div>
          <div class="text-content-fourth talking">
            <span class="text">
              Nasz zespół pracuje nad realizacją<br />
              projektu i jego kolejnymi etapami<br />
              Jest to również czas na wzajemne <br />
              konsultacje. Analizujemy każde<br />
              uwagi i wprowadzamy poprawki.
            </span>
          </div>
        </div>
      </div>
      <!--END OF no4 -->

      <!-- MENU BURGER -->
      <div class="title-wrapper-absolute">
        <div class="second-ham-container">
          <div class="first-ham">
            <Menu />
          </div>
        </div>
      </div>
      <!-- END OF MENU BURGER -->

      <!--END OF no5 -->
      <div class="ending-wrapper">
        <div class="vertical-line-rd"></div>
        <div class="content-wrapper" v-scrollanimation>
          <div class="text-wrapper">
            <div class="text-content">
              <h2>Zakończenie</h2>
              <div class="nr inline">5</div>
              <span class="text inline">
                Po spełnieniu Twoich <br />
                oczekiwań dopinamy wszystko<br />
                na ostatni guzik.<br />
                Wprowadzamy w życie <br />
                ukończony projekt<br />
                i zapewniamy całkowite<br />
                wsparcie techniczne :)<br />
              </span>
            </div>
            <div class="text-divider-rd"></div>
          </div>
          <div class="content-icon fifth-icon">
            <b-icon icon="door-open-fill " v-bind:scale="scale"></b-icon>
          </div>
          <div class="vertical-line-rd-rght"></div>
        </div>
      </div>

      <!--END OF no5 -->
    </div>
    <footerm />
  </section>
</template>

<script>
import footerm from "@/components/footer.vue";
import Menu from "@/components/MenuBurger.vue";
export default {
  name: "Partnership",
  components: {
    Menu,
    footerm
  },
  data() {
    return {
      scale: 18
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/Partnership/_Partnership.scss";
@import "@/assets/styles/Partnership/responsive/xlarge_D.scss";
@import "@/assets/styles/Partnership/responsive/large_D.scss";
@import "@/assets/styles/Partnership/responsive/medium_D.scss";
@import "@/assets/styles/Partnership/responsive/small_D.scss";
@import "@/assets/styles/Partnership/responsive/xsmall_D.scss";
</style>
